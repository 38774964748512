<template>
  <div class="page">
    <div class="top-container" @click="handlePreviewMap">
      <img
        src="https://img.miaocang.cc/pcImg/cloud/flower-tour/route/top.png"
        class="top-image"
        oncontextmenu="return false;"
      />
    </div>
    <div class="list-container">
      <img src="https://img.miaocang.cc/pcImg/cloud/flower-tour/route/part01.png" class="list-item" oncontextmenu="return false;" />
      <img src="https://img.miaocang.cc/pcImg/cloud/flower-tour/route/part02.png" class="list-item" oncontextmenu="return false;" />
      <img src="https://img.miaocang.cc/pcImg/cloud/flower-tour/route/part03.png" class="list-item" oncontextmenu="return false;" />
      <img src="https://img.miaocang.cc/pcImg/cloud/flower-tour/route/part04.png" class="list-item" oncontextmenu="return false;" />
      <img src="https://img.miaocang.cc/pcImg/cloud/flower-tour/route/part05.png" class="list-item" oncontextmenu="return false;" />
      <img src="https://img.miaocang.cc/pcImg/cloud/flower-tour/route/part06.png" class="list-item" oncontextmenu="return false;" />
      <img src="https://img.miaocang.cc/pcImg/cloud/flower-tour/route/part07.png" class="list-item" oncontextmenu="return false;" />
      <img src="https://img.miaocang.cc/pcImg/cloud/flower-tour/route/part08.png" class="list-item" oncontextmenu="return false;" />
    </div>

    <back-to-top />
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
import BackToTop from '@/components/BackToTop.vue'
import imgSrc from '@/utils/bigDataIMG.js'
const bigDataIMG = imgSrc.data()

export default {
  name: "FlowerTourRoute",

  components: { BackToTop },

  created() {
    document.title = '"探花游" 文旅介绍路线图'
  },

  methods: {
    /* 顶部区域的点击事件处理: 查看地图的大图预览 */
    handlePreviewMap() {
      ImagePreview({
        images: [bigDataIMG.flower_tour_map],
        closeable: true,
        showIndex: false,
        maxZoom: 10,
        overlayStyle: {
          'background-color': 'rgba(0, 0, 0, .6)'
        },
        getContainer() {
          return document.querySelector('.page')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  min-height: 100vh;
  max-width: 840px;
  margin: 0 auto;
  text-align: center;
  background-color: #f3d7b3;
  display: flex;
  flex-direction: column;
  .top-container {
    height: calc((100vw - 80px) * 1.25);
    max-height: calc((840px - 80px) * 1.25);
    .top-image {
      pointer-events: auto;
      object-position: 0 -100px;
    }
  }
  .list-container {
    display: flex;
    flex-direction: column;
  }
  img {
    width: 100%;
    user-select: none;
    pointer-events: none;
  }
}
</style>